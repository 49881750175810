import styled from 'styled-components'

import { HumanizedTokens } from 'utils'

type TypographyTokens = HumanizedTokens['components']['typography']

export interface TypographyProps {
  withoutMargin?: boolean
  variation?: keyof TypographyTokens['variation']
  color?: keyof TypographyTokens['color']
}

export const Typography = styled.p<TypographyProps>(
  ({
    variation = 'bodySmall',
    color = 'primary',
    withoutMargin = false,
    theme: {
      humanizedTokens: {
        components: { typography },
      },
    },
  }) => ({
    ...typography.variation[variation],
    ...typography.color[color],
    ...(withoutMargin ? { margin: 0 } : {}),
  })
)
