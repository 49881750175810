import { brandPrimitiveTokens } from 'utils'

const {
  colors,
  spacing,
  typography: { fontFamily },
} = brandPrimitiveTokens

const dialog = {
  actions: {
    border: `1px solid ${colors.gray[10]}`,
    gap: spacing[4],
    padding: spacing[6],
  },
  content: {
    margin: `0 ${spacing[6]} ${spacing[8]}`,
  },
  title: {
    margin: `${spacing[6]} ${spacing[6]} ${spacing[8]}`,
    font: `400 1.5rem / 1.5 ${fontFamily}`,
    color: colors.gray[80],
  },
}

export { dialog }
