import { brandPrimitiveTokens } from 'utils'

const { gray, isaacBlue } = brandPrimitiveTokens.colors

const baseSwitch = {
  focus: {
    borderColor: isaacBlue[30],
  },
  track: {
    on: {
      backgroundColor: isaacBlue[60],
      disabled: {
        backgroundColor: isaacBlue[20],
      },
    },
    off: {
      backgroundColor: gray[60],
      disabled: {
        backgroundColor: gray[10],
      },
    },
  },
  thumb: {
    borderColor: gray[10],
    on: {
      backgroundColor: isaacBlue[60],
      hover: {
        backgroundColor: isaacBlue[70],
      },
      active: {
        backgroundColor: isaacBlue[80],
      },
      disabled: {
        backgroundColor: isaacBlue[20],
      },
    },
    off: {
      backgroundColor: gray[50],
      hover: {
        backgroundColor: gray[70],
      },
      active: {
        backgroundColor: gray[90],
      },
      disabled: {
        backgroundColor: gray[10],
      },
    },
  },
}

export { baseSwitch }
