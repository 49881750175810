import React, { ReactNode } from 'react'
import styled from 'styled-components'

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`

export { ButtonGroup }
