import {
  baseSwitch,
  breadcrumbs,
  button,
  checkbox,
  chip,
  datePicker,
  dialog,
  formControlLabel,
  iconButton,
  link,
  radio,
  systemButton,
  table,
  tooltipButton,
  typography,
} from './components'

import { focus, transition } from './generic'

const components = {
  baseSwitch,
  breadcrumbs,
  button,
  checkbox,
  chip,
  datePicker,
  dialog,
  formControlLabel,
  iconButton,
  link,
  radio,
  systemButton,
  table,
  tooltipButton,
  typography,
}

const generic = {
  focus,
  transition,
}

export const brandHumanizedTokens = {
  components,
  generic,
}

export type HumanizedTokens = typeof brandHumanizedTokens
