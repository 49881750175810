/**
 * Função para transformar cor hexa em rgba definindo,
 * opcionalmente, o valor de opacidade.
 * @param {string} color - Valor da cor em hexadecimal (ex: #000000).
 * @param {string} alpha - Valor da opacidade (ex: 0.05).
 * @returns {string} a cor em rgba (ex: rgba(0, 0, 0, 0.5)).
 */
const hexColorToRGBA = (color: string, alpha: number = 1): string => {
  let hex = color.replace('#', '').toLowerCase()
  const numPattern = /^(?:[0-9a-f]{3}){1,2}$/

  if (!numPattern.test(hex)) {
    throw new Error("Parâmetro 'color' inválido.")
  }

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${alpha})`
}

export { hexColorToRGBA }
