import styled, { css } from 'styled-components'

import { brandPrimitiveTokens } from 'utils'
import { hexColorToRGBA } from 'utils/helpers/colors'
import { Typography } from 'components/Typography'
import { SideMenuLinkProps } from './index'

const {
  colors: { isaacBlue, gray },
} = brandPrimitiveTokens

export const SideMenuLink = styled.a<Pick<SideMenuLinkProps, 'disabled' | 'active'>>`
  &:visited {
    color: ${gray[50]};
  }

  display: flex;
  align-items: center;
  flex: 1;
  height: 64px;
  border-radius: 4px;
  text-decoration: none;
  color: ${gray[50]};
  cursor: pointer;
  transition: background-color 0.3s ease-out, color 0.3s ease-out;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus-visible {
        background-color: ${hexColorToRGBA(isaacBlue[60], 0.1)};
        color: ${isaacBlue[60]};
      }

      &:focus-visible {
        outline: 2px solid ${isaacBlue[30]};
        outline-offset: 1px;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${gray[20]};
      cursor: not-allowed;
    `}

    ${({ active }) =>
    active &&
    css`
      color: ${isaacBlue[60]};
    `}
`

export const SideMenuLabel = styled(Typography)`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  color: inherit;
`
SideMenuLabel.defaultProps = {
  variation: 'bodyLarge',
}

export const SideMenuTextIcon = styled(Typography)`
  display: flex;
  justify-content: center;
  flex: 0 0 64px;
  text-align: center;
  text-style: none;
  color: inherit;
`
SideMenuTextIcon.defaultProps = {
  variation: 'buttonLarge',
}

export const SideMenuIcon = styled.i`
  display: flex;
  justify-content: center;
  flex: 0 0 64px;
`
