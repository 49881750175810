import { ChipProps as MuiChipProps } from '@material-ui/core'
import { StyledMuiChip } from './index.style'
import classNames from 'classnames'
import { forwardRef } from 'react'

export interface CustomProps {
  variation?: 'gray' | 'green' | 'yellow' | 'coral'
}

export interface ChipProps
  extends Omit<MuiChipProps, 'avatar' | 'color' | 'icon' | 'deleteIcon' | 'size' | 'variant'>,
    CustomProps {}

const muiProps: MuiChipProps = {
  clickable: false,
  size: 'small',
  variant: 'default',
}

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
  ({ variation = 'gray', ...props }, ref) => (
    <StyledMuiChip
      {...muiProps}
      {...props}
      className={classNames(`color--${variation}`, {})}
      ref={ref}
    />
  )
) as React.ForwardRefExoticComponent<ChipProps & React.RefAttributes<HTMLDivElement>>
