import { FormControlLabelProps } from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import { StyledFormControlLabel } from './style'

export const FormControlLabel = ({
  label = '',
  labelPlacement = 'start',
  ...props
}: FormControlLabelProps) => (
  <StyledFormControlLabel label={<Typography variant="body2">{label}</Typography>} {...props} />
)
