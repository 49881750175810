import { brandPrimitiveTokens } from 'utils'

const {
  colors: { gray },
  spacing,
} = brandPrimitiveTokens

const systemButton = {
  base: {
    border_radius: '8px',
    padding: spacing[1],
    box_shadow: `0 0 0 2px transparent`,
    font_size: '20px',
    transitions: {
      base: 'all 0.2s cubic-bezier(.17,.67,.72,.66)',
      focus: 'box-shadow 0.3s cubic-bezier(.17,.67,.72,.66)',
    },
  },
  disable: {
    background_color: 'transparent',
    text_color: gray[40],
  },
  enable: {
    text_color: gray[50],
  },
  focus: {
    background_color: `${gray[70]}24`,
    text_color: gray[70],
  },
  hover: {
    background_color: 'transparent',
    text_color: gray[70],
  },
  press: {
    background_color: `${gray[90]}29`,
    text_color: gray[90],
  },
}

export { systemButton }
