import { FC } from 'react'
import FirstPageRounded from '@material-ui/icons/FirstPageRounded'
import LastPageRounded from '@material-ui/icons/LastPageRounded'
import NavigateBeforeRounded from '@material-ui/icons/NavigateBeforeRounded'
import NavigateNextRounded from '@material-ui/icons/NavigateNextRounded'
import MenuItem from '@material-ui/core/MenuItem'

import { Typography } from 'components/Typography'
import { SystemButton } from 'components/SystemButton'
import { TextField } from 'components/TextField'

import {
  StyledPaginationWrapper,
  StyledItensPerPageWrapper,
  StyledPageActionWrapper,
} from './style'

export interface PaginationProps {
  currentPage: number
  itensPerPage?: number
  itensPerPageOptions?: number[]
  totalItens: number
  onPageChange: (page: number) => any
  onItensPerChangeChange: (itensPerPage: number) => any
}

export const Pagination: FC<PaginationProps> = ({
  currentPage,
  itensPerPage,
  itensPerPageOptions,
  totalItens,
  onPageChange,
  onItensPerChangeChange,
}) => {
  const innerItensPerPage = itensPerPage || 10
  const innerItensPerPageOptions = itensPerPageOptions || [10, 15, 25, 50]
  const lastPage = Math.ceil(totalItens / innerItensPerPage)
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === lastPage || lastPage === 0

  const showingItens = {
    first: (currentPage - 1) * innerItensPerPage + 1,
    last: currentPage * innerItensPerPage,
  }

  const handleFirstPage = () => onPageChange(1)
  const handlePreviousPage = () => onPageChange(currentPage - 1)
  const handleNextPage = () => onPageChange(currentPage + 1)
  const handleLastPage = () => totalItens && onPageChange(lastPage)
  const handleItensPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onItensPerChangeChange(parseInt(event.target.value))
    onPageChange(1)
  }

  return (
    <StyledPaginationWrapper>
      <Typography variation="bodySmall" color="secondary">
        {showingItens.first}-{isLastPage ? totalItens : showingItens.last} de {totalItens}
      </Typography>

      <StyledPageActionWrapper>
        <SystemButton onClick={handleFirstPage} disabled={isFirstPage}>
          <FirstPageRounded />
        </SystemButton>

        <SystemButton onClick={handlePreviousPage} disabled={isFirstPage}>
          <NavigateBeforeRounded />
        </SystemButton>

        <SystemButton onClick={handleNextPage} disabled={isLastPage}>
          <NavigateNextRounded />
        </SystemButton>

        <SystemButton onClick={handleLastPage} disabled={isLastPage}>
          <LastPageRounded />
        </SystemButton>
      </StyledPageActionWrapper>

      <StyledItensPerPageWrapper>
        <Typography variation="caption" color="secondary">
          Linhas por página
        </Typography>

        <TextField
          select
          id="select"
          label="Select"
          value={itensPerPage}
          onChange={handleItensPerPage}
        >
          {innerItensPerPageOptions.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </StyledItensPerPageWrapper>
    </StyledPaginationWrapper>
  )
}
