import { useState } from 'react'
import { DatePickerProps as MuiDatePickerProps } from '@material-ui/pickers'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { StyledMuiDatePicker } from './DatePicker.style'

export interface DatePickerProps
  extends Omit<
    MuiDatePickerProps,
    'autoOk' | 'disableToolbar' | 'inputVariant' | 'maskChar' | 'orientation' | 'variant'
  > {}

const defaultProps: Omit<MuiDatePickerProps, 'value' | 'onChange'> = {
  format: 'DD/MM/YYYY',
  label: 'Label',
  invalidDateMessage: 'Formato de data inválido',
  minDateMessage: 'Data não deve ser menor do que a mínima',
  maxDateMessage: 'Data não deve ser menor do que a máxima',
}

export const DatePicker = (props: DatePickerProps) => (
  <StyledMuiDatePicker
    {...defaultProps}
    {...props}
    autoOk={true}
    disableToolbar={true}
    inputVariant="outlined"
    keyboardIcon={<CalendarTodayIcon />}
    maskChar=" "
    orientation="portrait"
    variant="inline"
  />
)
