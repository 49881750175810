import {
  ChangeEvent,
  useState,
  ReactNode,
  useEffect,
  Dispatch,
  SetStateAction,
  forwardRef,
} from 'react'
import { useDebounce } from 'hooks/useDebounce'

import { SideMenuSearch as StyledSideMenuSearch } from './styles'
import { SideMenuSearchInput } from './components/SideMenuSearchInput'
import { SideMenuSearchResult } from './components/SideMenuSearchResult'
import { SideMenuSearchClose } from './components/SideMenuSearchClose'

interface SideMenuSearchProps {
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
  staticOptions?: any[]
  getOptions: (inputValue: string) => Promise<any>
  renderOptions: (options: any[], inputValue?: string) => ReactNode[]
  inputValue: string
  setInputValue: Dispatch<SetStateAction<string>>
}

export const SideMenuSearch = forwardRef<HTMLInputElement, SideMenuSearchProps>(
  (
    {
      isVisible,
      setIsVisible,
      staticOptions,
      getOptions,
      renderOptions,
      inputValue,
      setInputValue,
    },
    ref
  ) => {
    const [options, setOptions] = useState<any[]>([])

    const handleCloseClick = () => {
      setInputValue('')
      setIsVisible(false)
    }

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.currentTarget.value)
    }

    const debounceGetOptions = useDebounce((inputValue: string) => {
      getOptions(inputValue).then(options => setOptions(options))
    }, 500)

    useEffect(() => {
      debounceGetOptions(inputValue)
    }, [inputValue])

    return (
      <StyledSideMenuSearch isVisible={isVisible}>
        <SideMenuSearchClose onClick={handleCloseClick} isVisible={!!inputValue} />
        <SideMenuSearchInput onChange={onInputChange} value={inputValue} ref={ref} />
        <SideMenuSearchResult>
          {renderOptions(staticOptions !== undefined ? staticOptions : options, inputValue)}
        </SideMenuSearchResult>
      </StyledSideMenuSearch>
    )
  }
)
