import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'
import { ChipProps } from '.'

import { HumanizedTokens } from 'utils'

type ChipTokens = HumanizedTokens['components']['chip']

const baseComponent = `
  border-radius: 24px;
  border: 2px solid;
  span {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    padding: 3px 8px;
  }
  .MuiChip-deleteIcon {
    width: 14px;
    height: 14px;
  }
`

const gray = (chip: ChipTokens) => `
  &.color--gray {
    background: ${chip.default.backgroundColor};
    border-color: ${chip.default.borderColor};
    span {
      color: ${chip.default.color};
    }
    .MuiChip-deleteIcon {
      color: ${chip.default.iconColor};
      &:hover {
        color: ${chip.default.hover.color}
      }
    }
  }
`

const green = (chip: ChipTokens) => `
  &.color--green {
    background: ${chip.green.backgroundColor};
    border-color: ${chip.green.borderColor};
    span {
      color: ${chip.green.color};
    }
    .MuiChip-deleteIcon {
      color: ${chip.green.iconColor};
      &:hover {
        color: ${chip.green.hover.color}
      }
    }
  }
`

const yellow = (chip: ChipTokens) => `
  &.color--yellow {
    background: ${chip.yellow.backgroundColor};
    border-color: ${chip.yellow.borderColor};
    span {
      color: ${chip.yellow.color};
    }
    .MuiChip-deleteIcon {
      color: ${chip.yellow.iconColor};
      &:hover {
        color: ${chip.yellow.hover.color}
      }
    }
  }
`

const coral = (chip: ChipTokens) => `
  &.color--coral {
    background: ${chip.coral.backgroundColor};
    border-color: ${chip.coral.borderColor};
    span {
      color: ${chip.coral.color};
    }
    .MuiChip-deleteIcon {
      color: ${chip.coral.iconColor};
      &:hover {
        color: ${chip.coral.hover.color};
      }
    }
  }
`

const focus = (chip: ChipTokens) => `
  &:focus-visible {
    border-color: ${chip.focus.borderColor};
  }
`

const disabled = (chip: ChipTokens) => `
  &.MuiChip-root.Mui-disabled {
    opacity: 1;
    background: ${chip.disabled.backgroundColor};
    border-color: ${chip.disabled.borderColor};
    span {
      color: ${chip.disabled.color};
    }
    .MuiChip-deleteIcon {
      color: ${chip.disabled.iconColor};
    }
  }
`

export const StyledMuiChip = styled(Chip)<ChipProps>`
  ${({
    theme: {
      humanizedTokens: {
        components: { chip },
      },
    },
  }) => `
    ${baseComponent}
    ${gray(chip)}
    ${green(chip)}
    ${yellow(chip)}
    ${coral(chip)}
    ${focus(chip)}
    ${disabled(chip)}
  `}
`
