import { brandPrimitiveTokens } from 'utils'
import { hexColorToRGBA } from 'utils/helpers/colors'

const { gray, isaacBlue } = brandPrimitiveTokens.colors

const table = {
  hover: {
    background_color: hexColorToRGBA(isaacBlue[60], 0.1),
  },
  focus: {
    border_color: isaacBlue[30],
  },
  selected: {
    background_color: hexColorToRGBA(isaacBlue[60], 0.14),
  },
  disabled: {
    color: gray[20],
  },
}

export { table }
