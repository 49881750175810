import styled, { css } from 'styled-components'

import { brandPrimitiveTokens } from 'utils'
import { hexColorToRGBA } from 'utils/helpers/colors'
import { StyledSideMenuNav } from 'components/SideMenu/components/SideMenuList/styles'

const {
  colors: { gray },
} = brandPrimitiveTokens

const transition =
  'width 0.2s cubic-bezier(0.42, 0.29, 0.39, 0.83), box-shadow 0.2s cubic-bezier(0.42, 0.29, 0.39, 0.83)'

const box_shadow = `0px 16px 44px 8px ${hexColorToRGBA(
  gray[10],
  0.36
)}, 0px 28px 82px 16px ${hexColorToRGBA(gray[10], 0.4)}`

interface SideMenuProps {
  hasSearch: boolean
}

const withSearch = css`
  width: 256px;
  box-shadow: ${box_shadow};
`

export const SideMenu = styled.header<SideMenuProps>`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column wrap;
  width: 96px;
  height: 100vh;
  background-color: ${gray[3]};
  transition: ${transition};
  ${({ hasSearch }) => hasSearch && withSearch}
  &:hover {
    ${withSearch}
  }

  ${StyledSideMenuNav} {
    max-width: 100%;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
`
