import { ThemeOptions } from '@material-ui/core/styles'
import { hexColorToRGBA } from 'utils/helpers/colors'
import { brandPrimitiveTokens } from 'utils/tokens/primitiveTokens'
import { brandHumanizedTokens } from 'utils/tokens/humanizedTokens'

const {
  variation: {
    headlineDesktopLarge,
    headlineDesktopMedium,
    headlineDesktopSmall,
    headlineDesktopXsmall,
    subtitleDesktopLarge,
    subtitleDesktopMedium,
    bodyLarge,
    bodySmall,
    buttonLarge,
    caption,
    overline,
  },
} = brandHumanizedTokens.components.typography

const {
  colors: { coral, isaacBlue, gray, yellow },
  spacing,
} = brandPrimitiveTokens

export const brandMaterialTheme: ThemeOptions = {
  palette: {
    common: {
      black: gray[100],
      white: gray[0],
    },
    background: {
      paper: gray[0],
      default: gray[2],
    },
    primary: {
      light: isaacBlue[30],
      main: isaacBlue[60],
      dark: isaacBlue[70],
      contrastText: gray[0],
    },
    secondary: {
      light: isaacBlue[30],
      main: isaacBlue[60],
      dark: isaacBlue[70],
      contrastText: gray[0],
    },
    error: {
      light: coral[40],
      main: coral[80],
      dark: coral[90],
      contrastText: gray[0],
    },
    warning: {
      light: yellow[30],
      main: yellow[70],
      dark: coral[80],
      contrastText: gray[0],
    },
    text: {
      primary: gray[80],
      secondary: gray[60],
      disabled: gray[40],
      hint: gray[50],
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: headlineDesktopLarge.fontFamily,
      fontWeight: headlineDesktopLarge.fontWeight,
      fontSize: headlineDesktopLarge.fontSize,
      lineHeight: headlineDesktopLarge.lineHeight,
      letterSpacing: '0',
    },
    h2: {
      fontFamily: headlineDesktopMedium.fontFamily,
      fontWeight: headlineDesktopMedium.fontWeight,
      fontSize: headlineDesktopMedium.fontSize,
      lineHeight: headlineDesktopMedium.lineHeight,
      letterSpacing: headlineDesktopMedium.letterSpacing,
    },
    h3: {
      fontFamily: headlineDesktopSmall.fontFamily,
      fontWeight: headlineDesktopSmall.fontWeight,
      fontSize: headlineDesktopSmall.fontSize,
      lineHeight: headlineDesktopSmall.lineHeight,
      letterSpacing: '0',
    },
    h4: {
      fontFamily: headlineDesktopXsmall.fontFamily,
      fontWeight: headlineDesktopXsmall.fontWeight,
      fontSize: headlineDesktopXsmall.fontSize,
      lineHeight: headlineDesktopXsmall.lineHeight,
      letterSpacing: headlineDesktopXsmall.letterSpacing,
    },
    h5: {
      fontFamily: headlineDesktopXsmall.fontFamily,
      fontWeight: headlineDesktopXsmall.fontWeight,
      fontSize: headlineDesktopXsmall.fontSize,
      lineHeight: headlineDesktopXsmall.lineHeight,
      letterSpacing: headlineDesktopXsmall.letterSpacing,
    },
    h6: {
      fontFamily: headlineDesktopXsmall.fontFamily,
      fontWeight: headlineDesktopXsmall.fontWeight,
      fontSize: headlineDesktopXsmall.fontSize,
      lineHeight: headlineDesktopXsmall.lineHeight,
      letterSpacing: headlineDesktopXsmall.letterSpacing,
    },
    subtitle1: {
      fontFamily: subtitleDesktopLarge.fontFamily,
      fontWeight: subtitleDesktopLarge.fontWeight,
      fontSize: subtitleDesktopLarge.fontSize,
      lineHeight: subtitleDesktopLarge.lineHeight,
      letterSpacing: subtitleDesktopLarge.letterSpacing,
    },
    subtitle2: {
      fontFamily: subtitleDesktopMedium.fontFamily,
      fontWeight: subtitleDesktopMedium.fontWeight,
      fontSize: subtitleDesktopMedium.fontSize,
      lineHeight: subtitleDesktopMedium.lineHeight,
      letterSpacing: subtitleDesktopMedium.letterSpacing,
    },
    body1: {
      fontFamily: bodyLarge.fontFamily,
      fontWeight: bodyLarge.fontWeight,
      fontSize: bodyLarge.fontSize,
      lineHeight: bodyLarge.lineHeight,
      letterSpacing: bodyLarge.letterSpacing,
    },
    body2: {
      fontFamily: bodySmall.fontFamily,
      fontWeight: bodySmall.fontWeight,
      fontSize: bodySmall.fontSize,
      lineHeight: bodySmall.lineHeight,
      letterSpacing: bodySmall.letterSpacing,
    },
    button: {
      fontFamily: buttonLarge.fontFamily,
      fontWeight: buttonLarge.fontWeight,
      fontSize: buttonLarge.fontSize,
      lineHeight: buttonLarge.lineHeight,
      letterSpacing: buttonLarge.letterSpacing,
      textTransform: buttonLarge.textTransform,
    },
    caption: {
      fontFamily: caption.fontFamily,
      fontWeight: caption.fontWeight,
      fontSize: caption.fontSize,
      lineHeight: caption.lineHeight,
      letterSpacing: caption.letterSpacing,
    },
    overline: {
      fontFamily: overline.fontFamily,
      fontWeight: overline.fontWeight,
      fontSize: overline.fontSize,
      lineHeight: overline.lineHeight,
      letterSpacing: overline.letterSpacing,
      textTransform: overline.textTransform,
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: gray[80],
        color: gray[0],
        fontWeight: 400,
        fontSize: '0.75rem',
        letterSpacing: '0.4px',
        lineHeight: 1.4,
        padding: `${spacing[1]} ${spacing[2]}`,
      },
      tooltipPlacementBottom: {
        margin: `${spacing[2]} 0`,
        '@media (min-width: 600px)': {
          margin: `${spacing[2]} 0`,
        },
      },
    },
    MuiPopover: {
      paper: {
        border: `1px solid ${gray[10]}`,
        borderRadius: spacing[3],
        boxShadow: `0px 8px 10px ${hexColorToRGBA(gray[90], 0.04)},
        0px 3px 14px ${hexColorToRGBA(gray[90], 0.02)},
        0px 5px 5px ${hexColorToRGBA(gray[90], 0.06)}`,
        marginTop: spacing[1],
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        borderRadius: spacing[1],
        '&:hover': {
          backgroundColor: `${hexColorToRGBA(isaacBlue[60], 0.08)}`,
        },
      },
      dayLabel: {
        width: '32px',
      },
      transitionContainer: {
        height: '28px',
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: '192px',
      },
    },
    MuiPickersDay: {
      day: {
        width: '32px',
        height: '32px',
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: `${hexColorToRGBA(isaacBlue[60], 0.08)}`,
        },
      },
      daySelected: {
        color: gray[0],
        backgroundColor: isaacBlue[60],
        borderRadius: '4px',
      },
      current: {
        color: isaacBlue[60],
      },
      hidden: {
        opacity: 0.3,
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: spacing[2],
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: 12,
        paddingBottom: 12,
        lineHeight: 1.5,
      },
    },
    MuiListItem: {
      root: {
        color: gray[80],
        '&.Mui-disabled': {
          color: gray[40],
        },
        '&.Mui-selected, &.Mui-selected:hover': {
          backgroundColor: hexColorToRGBA(isaacBlue[10], 0.8),
          color: isaacBlue[60],
        },
      },
      button: {
        '&:hover': {
          backgroundColor: hexColorToRGBA(isaacBlue[60], 0.1),
        },
        '&:active': {
          backgroundColor: hexColorToRGBA(isaacBlue[60], 0.14),
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: gray[50],
        '&:not(.MuiFormLabel-filled) + .MuiInputBase-root .MuiInputAdornment-root': {
          color: gray[50],
        },
        '&:not(.MuiFormLabel-filled) + .MuiInputBase-root .MuiInputAdornment-root .MuiTypography-root': {
          color: gray[50],
        },
      },
      outlined: {
        transform: 'translate(14px, 16px) scale(1)',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
          borderColor: gray[70],
        },
        '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
          borderColor: isaacBlue[30],
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderWidth: 2,
        },
      },
      input: {
        padding: '14.5px 14px',
      },
      notchedOutline: {
        borderColor: gray[20],
        borderRadius: 8,
      },
      adornedStart: {
        paddingLeft: 12,
      },
      adornedEnd: {
        paddingRight: 12,
      },
    },
    MuiInputAdornment: {
      root: {
        color: gray[80],
        '& .MuiTypography-root': {
          color: gray[80],
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormGroup: {
      root: {
        '& .MuiFormControlLabel-root': {
          marginBottom: 16,
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: hexColorToRGBA(gray[80], 0.3),
      },
    },
    MuiPaper: {
      elevation24: {
        boxShadow: `0px 24px 38px ${hexColorToRGBA(gray[90], 0.02)}, 0px 9px 46px ${hexColorToRGBA(
          gray[90],
          0.02
        )}, 0px 11px 15px ${hexColorToRGBA(gray[90], 0.08)}`,
      },
    },
    MuiTextField: {
      root: {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
          paddingTop: 6,
          paddingBottom: 5,
        },
      },
    },
    MuiTableRow: {
      root: {
        '&:last-child td': {
          borderTop: `1px solid ${gray[5]}`,
          borderBottom: 0,
        },
      },
    },
    MuiTableCell: {
      root: {
        borderTop: `1px solid ${gray[5]}`,
        borderBottom: 0,
        padding: '0px 16px',
      },
    },
    MuiTableContainer: {
      root: {
        overflowX: 'unset',
      },
    },
  },
}
