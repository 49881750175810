import { brandPrimitiveTokens } from 'utils'

const link = {
  hover: {
    color: brandPrimitiveTokens.colors.isaacBlue[70],
  },
  pressed: {
    color: brandPrimitiveTokens.colors.gray[80],
  },
}

export { link }
