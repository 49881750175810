import React from 'react'
import { IconButtonProps as MuiIconButtonProps } from '@material-ui/core/IconButton'
import classNames from 'classnames'

import { StyledMuiIconButton } from './index.style'
import { HumanizedTokens } from 'utils'
import { CircularProgress } from 'components/CircularProgress'

export interface CustomProps {
  variation?: keyof HumanizedTokens['components']['iconButton']['variants']
  size?: keyof HumanizedTokens['components']['iconButton']['sizes']
  loading?: boolean
}

export interface IconButtonProps
  extends Omit<
      MuiIconButtonProps,
      | 'centerRipple'
      | 'color'
      | 'disableFocusRipple'
      | 'disableRipple'
      | 'disableTouchRipple'
      | 'edge'
      | 'focusRipple'
      | 'size'
      | 'TouchRippleProps'
    >,
    CustomProps {}

const muiProps: MuiIconButtonProps = {
  color: 'primary',
}

const inversedLoading = (
  variation: keyof HumanizedTokens['components']['iconButton']['variants']
) => variation === 'ghost'

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      variation = 'primary',
      size = 'large',
      loading = false,
      disabled = false,
      children,
      ...props
    },
    ref
  ) => (
    <StyledMuiIconButton
      {...muiProps}
      {...props}
      disabled={disabled}
      className={classNames(`--${variation}`, `--${size}`)}
      ref={ref}
    >
      {loading ? <CircularProgress inverse={inversedLoading(variation)} /> : children}
    </StyledMuiIconButton>
  )
) as React.ForwardRefExoticComponent<IconButtonProps & React.RefAttributes<HTMLButtonElement>>
