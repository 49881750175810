import React from 'react'
import MuiBreadcrumbs, {
  BreadcrumbsProps as MuiBreadcrumbsProps,
} from '@material-ui/core/Breadcrumbs'

import { StyledMuiBreadcrumbs } from 'components/Breadcrumbs/index.style'

export interface BreadcrumbsProps
  extends Omit<
    MuiBreadcrumbsProps,
    'expandText' | 'itemsAfterCollapse' | 'itemsBeforeCollapse' | 'maxItems' | 'separator'
  > {}

export const Breadcrumbs = ({ ...props }: BreadcrumbsProps) => (
  <StyledMuiBreadcrumbs aria-label="breadcrumb" {...props} />
)
