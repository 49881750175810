import { brandPrimitiveTokens } from 'utils'

const {
  colors: { isaacBlue, gray },
  spacing,
} = brandPrimitiveTokens

const iconButton = {
  base: {
    border_radius: '8px',
    padding: spacing[3],
    box_shadow: `0 0 0 2px transparent`,
    transitions: {
      base: 'all 0.2s cubic-bezier(.17,.67,.72,.66)',
      focus: 'box-shadow 0.3s cubic-bezier(.17,.67,.72,.66)',
    },
  },
  variants: {
    primary: {
      enable: {
        background_color: isaacBlue[60],
        text_color: gray[0],
      },
      hover: {
        background_color: isaacBlue[70],
      },
      press: {
        background_color: isaacBlue[80],
      },
      disable: {
        background_color: gray[10],
        text_color: gray[40],
      },
      load: {
        background_color: isaacBlue[60],
      },
      focus: {
        box_shadow: `0 0 0 2px ${isaacBlue[30]}`,
      },
    },
    ghost: {
      hover: {
        background_color: `${isaacBlue[60]}14`,
      },
      press: {
        background_color: `${isaacBlue[60]}1F`,
      },
      disable: {
        background_color: 'transparent',
        text_color: gray[40],
      },
      focus: {
        box_shadow: `0 0 0 2px ${isaacBlue[30]}`,
      },
    },
  },
  sizes: {
    small: {
      iconSize: '20px',
      padding: '6px',
      size: '32px',
    },
    large: {
      size: '48px',
    },
  },
}

export { iconButton }
