import { brandPrimitiveTokens } from 'utils'

const { gray } = brandPrimitiveTokens.colors

const formControlLabel = {
  base: {
    color: gray[80],
  },
  disabled: {
    color: gray[40],
  },
}

export { formControlLabel }
