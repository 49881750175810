import { CSSProperties } from 'react'
import { brandPrimitiveTokens } from 'utils'

const {
  colors: { gray, isaacBlue },
} = brandPrimitiveTokens

type TypographyVariations =
  | 'headlineDesktopLarge'
  | 'headlineDesktopMedium'
  | 'headlineDesktopSmall'
  | 'headlineDesktopXsmall'
  | 'subtitleDesktopLarge'
  | 'subtitleDesktopMedium'
  | 'subtitleDesktopSmall'
  | 'bodyLarge'
  | 'bodySmall'
  | 'buttonLarge'
  | 'caption'
  | 'overline'

type TypographyColors = 'primary' | 'secondary' | 'accent' | 'accentContrast'

export interface TypographyTokens {
  variation: Record<
    TypographyVariations,
    Pick<
      CSSProperties,
      'fontFamily' | 'fontWeight' | 'fontSize' | 'lineHeight' | 'letterSpacing' | 'textTransform'
    >
  >
  color: Record<TypographyColors, Pick<CSSProperties, 'color'>>
}

const typography: TypographyTokens = {
  variation: {
    headlineDesktopLarge: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: 1.5,
    },
    headlineDesktopMedium: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '2.125rem',
      lineHeight: 1.4117647059,
      letterSpacing: '0.25px',
    },
    headlineDesktopSmall: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.5,
    },
    headlineDesktopXsmall: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.5,
      letterSpacing: '0.15px',
    },
    subtitleDesktopLarge: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1.125rem',
      lineHeight: 1.5555555556,
      letterSpacing: '0.15px',
    },
    subtitleDesktopMedium: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.5px',
    },
    subtitleDesktopSmall: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.5px',
    },
    bodyLarge: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 2,
      letterSpacing: '0.5px',
    },
    bodySmall: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.4285714286,
      letterSpacing: '0.25px',
    },
    buttonLarge: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.5,
      letterSpacing: '0.4px',
    },
    overline: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.6,
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
    },
  },
  color: {
    primary: {
      color: gray[80],
    },
    secondary: {
      color: gray[60],
    },
    accent: {
      color: isaacBlue[60],
    },
    accentContrast: {
      color: gray[0],
    },
  },
}

export { typography }
