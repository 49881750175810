import MuiTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import MuiTableRow from '@material-ui/core/TableRow'
import { CircularProgress } from 'components/CircularProgress'
import TableColumnCell, { TableColumns } from './TableColumnCell'
import TableRow, { StyledMuiTableRow, TableRowProps } from './TableRow'
import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react'
import { TableActionBar } from 'components/Table/TableActionBar'
import { Typography } from 'components/Typography'
import { TableCell } from '@material-ui/core'

export type TableProps<T extends Record<string, any> = Record<string, any>> = {
  columns: TableColumns
  isLoading?: boolean
  onRowClick?: TableRowProps<T>['onRowClick']
  orderBy?: string
  rowDisabled?: (row: T) => boolean
  rows: TableRow<T>[]
  rowsSelected?: Array<string>
  sorted?: boolean
  sortingHandler?: (field: string) => void
  checkable?: boolean
  checkedRows?: T[]
  setCheckedRows?: Dispatch<SetStateAction<T[]>>
  isRowCheckable?: (row: T) => boolean
  emptyRender?: ReactElement
}

export interface CheckableController {
  isDisabled: boolean
  isCheckable: boolean
  isChecked: boolean
}

const Table = function <T extends Record<string, any> = Record<string, any>>({
  columns,
  onRowClick,
  rows,
  rowsSelected,
  sorted,
  sortingHandler,
  orderBy,
  isLoading,
  rowDisabled,
  checkable = false,
  setCheckedRows,
  isRowCheckable,
  emptyRender = <Typography>Nenhum resultado encontrado</Typography>,
}: TableProps<T>) {
  const validatedRows = Array.isArray(rows) ? rows : []

  const sortedRows = [...validatedRows].sort((a, b) => {
    const prevRowValue = a[orderBy!]
    const nextRowValue = b[orderBy!]
    if (typeof prevRowValue === 'string' && typeof nextRowValue === 'string') {
      return prevRowValue.localeCompare(nextRowValue)
    }
    if (typeof prevRowValue === 'number' && typeof nextRowValue === 'number') {
      return prevRowValue - nextRowValue
    }
    return 1
  })
  const innerRows = orderBy ? sortedRows : validatedRows

  const initCheckedRowsController: () => CheckableController[] = () =>
    new Array(innerRows.length).fill(undefined).map((_, index) => {
      const rowData = innerRows[index]
      return {
        isDisabled: rowDisabled ? rowDisabled(rowData) : false,
        isCheckable: checkable && isRowCheckable ? isRowCheckable(rowData) : checkable,
        isChecked: false,
      }
    })

  const [checkedRowsController, setCheckedRowsController] = useState<CheckableController[]>(() =>
    initCheckedRowsController()
  )

  useEffect(
    () =>
      setCheckedRows &&
      setCheckedRows(rows.filter((_, rowIndex) => checkedRowsController[rowIndex].isChecked)),
    [checkedRowsController]
  )

  const selectAllHandler = (e: any) => {
    const hasAnyCheckableRowUncheked = checkedRowsController.some(
      checkController => checkController.isCheckable && checkController.isChecked === false
    )

    hasAnyCheckableRowUncheked
      ? setCheckedRowsController(
          checkedRowsController?.map(checkController =>
            checkController.isCheckable ? { ...checkController, isChecked: true } : checkController
          )
        )
      : setCheckedRowsController(initCheckedRowsController())
  }

  const checkableStateCounter = () => {
    const defaultValueCounter = { checked: 0, unchecked: 0 }
    return checkedRowsController.reduce((accumulator, checkController) => {
      checkController.isCheckable &&
        accumulator[checkController.isChecked ? 'checked' : 'unchecked']++
      return accumulator
    }, defaultValueCounter)
  }

  const checkableCounter = checkableStateCounter()

  const currentCheckableState = () => {
    const checkableRowsCount = checkableCounter.checked + checkableCounter.unchecked
    if (checkableCounter.checked === checkableRowsCount) return 'checked'
    if (checkableCounter.unchecked === checkableRowsCount) return 'unchecked'
    return 'indeterminated'
  }

  const checkClickWithDataHandler = (row: T, rowIndex: number) => (event: any) => {
    if (!checkedRowsController || !checkedRowsController[rowIndex].isCheckable) return
    const newCheckedRows = checkedRowsController.map((rowController, index) =>
      index === rowIndex ? { ...rowController, isChecked: !rowController.isChecked } : rowController
    )
    setCheckedRowsController(newCheckedRows)
  }

  useEffect(() => {
    setCheckedRowsController(initCheckedRowsController())
  }, [rows])

  const tableContent = () => {
    if (isLoading) {
      return (
        <StyledMuiTableRow>
          <TableCell colSpan={columns.length} height={64} style={{ textAlign: 'center' }}>
            <CircularProgress size={24} />
          </TableCell>
        </StyledMuiTableRow>
      )
    }

    return innerRows.length > 0 ? (
      innerRows?.map((row, rowIndex) => (
        <TableRow<T>
          key={row.key}
          row={row}
          rowIndex={rowIndex}
          columns={columns}
          onRowClick={onRowClick}
          rowsSelected={rowsSelected}
          disabled={checkedRowsController[rowIndex]?.isDisabled}
          checkable={checkedRowsController[rowIndex]?.isCheckable}
          checkedRow={checkedRowsController[rowIndex]?.isChecked}
          onCheckableClick={checkClickWithDataHandler(row, rowIndex)}
        />
      ))
    ) : (
      <StyledMuiTableRow>
        <TableCell colSpan={columns.length} height={64} style={{ textAlign: 'center' }}>
          {emptyRender}
        </TableCell>
      </StyledMuiTableRow>
    )
  }

  return (
    <>
      <TableActionBar
        counter={checkableCounter.checked}
        onClear={_ => {
          setCheckedRowsController(initCheckedRowsController())
        }}
      />
      <TableContainer>
        <MuiTable>
          <TableHead>
            <MuiTableRow>
              {columns.map((column, index) => (
                <TableColumnCell
                  key={`${column?.field}${index}`}
                  column={column}
                  direction={orderBy === column.field ? 'asc' : 'desc'}
                  orderBy={orderBy}
                  sorted={sorted}
                  sortingHandler={sortingHandler}
                  checkable={checkable && index === 0}
                  checkableState={currentCheckableState()}
                  selectAllHandler={selectAllHandler}
                />
              ))}
            </MuiTableRow>
          </TableHead>
          <TableBody>{tableContent()}</TableBody>
        </MuiTable>
      </TableContainer>
    </>
  )
}

export { Table }
