import { FC, MouseEventHandler, ReactElement } from 'react'

import CloseRounded from '@material-ui/icons/CloseRounded'
import ArrowBack from '@material-ui/icons/ArrowBack'

import { SystemButton } from 'components/SystemButton'
import { Typography } from 'components/Typography'

import { StyledActionDrawerWrapper, StyledActionDrawerActionsWrapper } from './style'

export interface ActionDrawerHeaderProps {
  title: string
  subtitle?: string
  extraContent?: ReactElement
  onBack?: MouseEventHandler<HTMLButtonElement>
  onClose?: MouseEventHandler<HTMLButtonElement>
}

export const ActionDrawerHeader: FC<ActionDrawerHeaderProps> = ({
  title,
  subtitle,
  extraContent,
  onBack,
  onClose,
}) => (
  <StyledActionDrawerWrapper>
    {(onClose || onBack) && (
      <StyledActionDrawerActionsWrapper>
        {onClose && (
          <SystemButton onClick={onClose} aria-label="Fechar">
            <CloseRounded />
          </SystemButton>
        )}

        {onBack && (
          <SystemButton onClick={onBack} aria-label="Voltar">
            <ArrowBack />
          </SystemButton>
        )}
      </StyledActionDrawerActionsWrapper>
    )}

    <Typography variation="headlineDesktopSmall" withoutMargin>
      {title}
    </Typography>

    {subtitle && (
      <Typography variation="subtitleDesktopMedium" withoutMargin>
        {subtitle}
      </Typography>
    )}

    {extraContent && <div>{extraContent}</div>}
  </StyledActionDrawerWrapper>
)
