import styled, { CSSObject } from 'styled-components'

import { Typography } from 'components/Typography'
import { NotificationProps, NotificationVariation } from 'components/Notification'
import { hexColorToRGBA } from 'utils/helpers/colors'

export const StyledNotificationIconWrapper = styled.div({
  svg: {
    verticalAlign: 'bottom',
  },
})

export const StyledNotificationTextWrapper = styled.div({
  display: 'flex',
  flexFlow: 'column',
  gap: 4,
  marginRight: 'auto',
  [Typography]: {
    margin: 0,
  },
})

export const StyledNotificationActionsWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
})

interface StyledNotificationWrapperProps
  extends Required<Pick<NotificationProps, 'variation' | 'floating'>> {
  hasTitle: boolean
}

export const StyledNotificationWrapper = styled.div<StyledNotificationWrapperProps>(
  ({
    theme: {
      primitiveTokens: {
        colors: { green, yellow, coral, isaacBlue, gray },
      },
    },
    variation,
    floating,
    hasTitle,
  }) => {
    const variations: Record<
      NotificationVariation,
      { backgroundColor: string; iconColor: string }
    > = {
      success: {
        backgroundColor: green[20],
        iconColor: green[70],
      },
      warning: {
        backgroundColor: yellow[20],
        iconColor: yellow[70],
      },
      error: {
        backgroundColor: coral[20],
        iconColor: coral[70],
      },
      information: {
        backgroundColor: isaacBlue[10],
        iconColor: isaacBlue[60],
      },
    }

    const floatingStyle: CSSObject = {
      position: 'fixed',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: 20,
      borderRadius: 8,
      boxShadow: `0px 16px 12px -6px ${hexColorToRGBA(
        gray[10],
        0.32
      )}, 0px 28px 38px ${hexColorToRGBA(gray[10], 0.48)};`,
    }

    return {
      display: 'flex',
      alignItems: 'center',
      gap: 12,
      padding: '20px',
      backgroundColor: variations[variation].backgroundColor,
      [StyledNotificationIconWrapper]: {
        alignSelf: hasTitle ? 'start' : 'auto',
        color: variations[variation].iconColor,
      },
      ...(floating ? floatingStyle : {}),
    }
  }
)
