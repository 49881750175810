import { FC } from 'react'

import { ButtonDockerWrapper, StyledTypography, StyledButtonDockerButtons } from './style'

export interface ButtonDockerProps {
  description?: string
}

export const ButtonDocker: FC<ButtonDockerProps> = ({ description, children }) => (
  <ButtonDockerWrapper>
    {description && (
      <StyledTypography variation="bodySmall" color="secondary" withoutMargin>
        {description}
      </StyledTypography>
    )}

    <StyledButtonDockerButtons>{children}</StyledButtonDockerButtons>
  </ButtonDockerWrapper>
)
