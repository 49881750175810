import styled from 'styled-components'

export const StyledActionDrawerWrapper = styled.div(
  ({
    theme: {
      primitiveTokens: {
        spacing,
        colors: { gray },
      },
    },
  }) => ({
    display: 'flex',
    gap: 12,
    flexFlow: 'column',
    padding: spacing[6],
    borderBottom: `1px solid ${gray[10]}`,
  })
)

export const StyledActionDrawerActionsWrapper = styled.div(
  ({
    theme: {
      primitiveTokens: { spacing },
    },
  }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row-reverse',
    marginBottom: `${spacing[1]}`,
  })
)
