import { Dispatch, MouseEvent, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react'

import { SideMenu as StyledSideMenu } from './styles'

import { BrandLogo } from 'components/BrandLogo'
import { SideMenuLogo } from 'components/SideMenu/components/SideMenuLogo'
import { SideMenuSearch } from 'components/SideMenu/components/SideMenuSearch'
import { useOutsideClick } from 'hooks/useClickedOutside'

export interface SideMenuProps {
  menuList: (setSearchIsVisible: Dispatch<SetStateAction<boolean>>) => ReactNode
  options?: any[]
  getOptions: (inputValue: string) => Promise<any>
  renderOptions: (options: any[], inputValue?: string) => ReactNode[]
}

export const SideMenu = ({ menuList, getOptions, renderOptions, options }: SideMenuProps) => {
  const [searchIsVisible, setSearchIsVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [searchIsVisible])

  const menuRef = useOutsideClick(() => {
    setInputValue('')
    setSearchIsVisible(false)
  })

  const onMouseLeave = () => {
    inputValue === '' && setSearchIsVisible(false)
  }

  return (
    <StyledSideMenu onMouseLeave={onMouseLeave} ref={menuRef} hasSearch={!!inputValue}>
      <SideMenuLogo>
        <BrandLogo />
      </SideMenuLogo>

      {menuList(setSearchIsVisible)}

      <SideMenuSearch
        isVisible={searchIsVisible}
        setIsVisible={setSearchIsVisible}
        staticOptions={options}
        getOptions={getOptions}
        renderOptions={renderOptions}
        inputValue={inputValue}
        setInputValue={setInputValue}
        ref={inputRef}
      />
    </StyledSideMenu>
  )
}
