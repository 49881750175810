import styled from 'styled-components'
import Link, { LinkProps } from '@material-ui/core/Link'

import { HumanizedTokens } from 'utils'

type LinkTokens = HumanizedTokens['components']['link']
type FocusTokens = HumanizedTokens['generic']['focus']
type TranisitionTokens = HumanizedTokens['generic']['transition']

const base = (tokens: FocusTokens, transition: TranisitionTokens) => `
  outline: ${tokens.as_outline.outline};
  transition: outline ${transition.small};
  &:focus-visible {
    outline: ${tokens.as_outline.focus.outline}
  }
`

const hoverState = (hoverTokens: LinkTokens['hover']) => `
  &:hover {
    color: ${hoverTokens.color};
  }
`

const pressedState = (pressTokens: LinkTokens['pressed']) => `
  &:active {
    color: ${pressTokens.color};
  }
`

export const StyledMuiLink = styled(Link)<LinkProps>`
  ${({
    theme: {
      humanizedTokens: {
        components: { link },
        generic: { focus, transition },
      },
    },
  }) => `
    ${base(focus, transition)}
    ${hoverState(link.hover)}
    ${pressedState(link.pressed)}
  `}
`
