import { brandPrimitiveTokens } from 'utils'
import { hexColorToRGBA } from 'utils/helpers/colors'

const datePicker = {
  base: {
    border_color: brandPrimitiveTokens.colors.gray[20],
    background_color: brandPrimitiveTokens.colors.gray[90],
    hover: {
      border_color: brandPrimitiveTokens.colors.gray[70],
      background_color: hexColorToRGBA(brandPrimitiveTokens.colors.gray[90], 0.16),
    },
    focus: {
      border_color: brandPrimitiveTokens.colors.isaacBlue[30],
    },
  },
}

export { datePicker }
