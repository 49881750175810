import { useEffect, useRef } from 'react'

export const useOutsideClick = (handler: Function) => {
  const domRef = useRef<HTMLElement>(null)

  useEffect(() => {
    let maybeHandler: EventListener = event => {
      const current = !domRef.current
      if (!domRef.current?.contains(event.target as Element)) {
        handler()
      }
    }

    document.addEventListener('mousedown', maybeHandler)

    return () => {
      document.removeEventListener('mousedown', maybeHandler)
    }
  })

  return domRef
}
