const colors = {
  isaacBlue: {
    10: '#ECEDFB',
    20: '#D8DCF7',
    30: '#B1B8EF',
    40: '#8B95E7',
    50: '#6471DF',
    60: '#3D4ED7',
    70: '#2636B7',
    80: '#1C2889',
    90: '#182272',
    100: '#131B5C',
  },
  navyBlue: {
    10: '#D2D2FF',
    20: '#A6A4FF',
    30: '#7977FF',
    40: '#4D49FF',
    50: '#201CFF',
    60: '#0400EE',
    70: '#0300C0',
    80: '#030093',
    90: '#020065',
    100: '#010038',
  },
  yellow: {
    10: '#FFFAE5',
    20: '#FFF5CC',
    30: '#FFEB99',
    40: '#FFE166',
    50: '#FFD733',
    60: '#FFCD00',
    70: '#E6B900',
    80: '#B38F00',
    90: '#806700',
    100: '#4D3E00',
  },
  beige: {
    10: '#FFFAF5',
    20: '#FEF5EC',
    30: '#FEEBD9',
    40: '#FDE2C5',
    50: '#FDD8B2',
    60: '#FCCE9F',
    70: '#FBBA77',
    80: '#F89127',
    90: '#C76806',
    100: '#783E04',
  },
  coral: {
    10: '#FEF1F0',
    20: '#FEE3E1',
    30: '#FDC8C4',
    40: '#FBACA6',
    50: '#FA9189',
    60: '#F9756B',
    70: '#F85549',
    80: '#EF1A0A',
    90: '#AB1207',
    100: '#670B04',
  },
  green: {
    10: '#E9FAEB',
    20: '#D2F6D7',
    30: '#A6ECB0',
    40: '#79E388',
    50: '#4DD961',
    60: '#2AC640',
    70: '#26B23A',
    80: '#1D8B2D',
    90: '#156320',
    100: '#0D3B13',
  },
  gray: {
    0: '#FFFFFF',
    1: '#FCFCFC',
    2: '#F9F9F9',
    3: '#F6F6F6',
    4: '#F3F3F3',
    5: '#F0F0F0',
    10: '#DADADA',
    20: '#C4C4C4',
    30: '#AFAFAF',
    40: '#999999',
    50: '#838383',
    60: '#6D6D6D',
    70: '#575757',
    80: '#414141',
    90: '#2C2C2C',
    100: '#161616',
  },
}

const spacing = {
  0: '0px',
  1: '4px',
  2: '8px',
  3: '12px',
  4: '16px',
  5: '20px',
  6: '24px',
  7: '28px',
  8: '32px',
  9: '36px',
  10: '40px',
  11: '44px',
  12: '48px',
  13: '52px',
  14: '56px',
  15: '60px',
  16: '64px',
  17: '68px',
  18: '72px',
  19: '76px',
  20: '80px',
}

const typography = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
}

export const brandPrimitiveTokens = {
  colors,
  spacing,
  typography,
}

export type PrimitiveTokens = typeof brandPrimitiveTokens
