import { ChangeEvent, FC, MouseEventHandler, useState } from 'react'

import { StyledSearchWrapper, StyledSearchInput, StyledSearchIcon } from './style'
import { StyledSearchClear } from 'components/Search/components/SideMenuSearchClose'

export interface SearchProps {
  placeholder: string
  onChange: (inputValue: string) => void
  initialValue?: string
  disabled?: boolean
  onClear?: Function
  onClick?: Function
}

export const Search: FC<SearchProps> = ({
  placeholder,
  onChange,
  initialValue = '',
  disabled = false,
  onClear,
  onClick,
}) => {
  const [inputValue, setInputValue] = useState(initialValue)
  const clearIsVisible = inputValue.length > 0

  const handleOnClickClear = () => {
    setInputValue('')
    onClear && onClear()
  }

  const handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value)
    onChange && onChange(event.currentTarget.value)
  }

  const handleOnClickInput: MouseEventHandler<HTMLInputElement> = params => {
    onClick && onClick(params)
  }

  return (
    <StyledSearchWrapper>
      <StyledSearchInput
        placeholder={placeholder}
        value={inputValue}
        onChange={handleOnChangeInput}
        onClick={handleOnClickInput}
        disabled={disabled}
      />
      <StyledSearchIcon />
      <StyledSearchClear onClick={handleOnClickClear} isVisible={clearIsVisible} />
    </StyledSearchWrapper>
  )
}
