import React from 'react'
import { ChangeEventHandler, InputHTMLAttributes } from 'react'
import { SideMenuSearchInput as StyledSideMenuSearchInput } from './styles'

interface SideMenuSearchInput extends InputHTMLAttributes<HTMLInputElement> {
  onChange: ChangeEventHandler<HTMLInputElement>
}

export const SideMenuSearchInput = React.forwardRef<HTMLInputElement, SideMenuSearchInput>(
  ({ onChange, ...props }, ref) => (
    <StyledSideMenuSearchInput
      {...props}
      onChange={onChange}
      ref={ref}
      placeholder="Pesquisar escolas..."
    />
  )
)
