import { FC, MouseEventHandler, ReactElement } from 'react'

import CloseRounded from '@material-ui/icons/CloseRounded'
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded'
import WarningRounded from '@material-ui/icons/WarningRounded'
import ErrorRounded from '@material-ui/icons/ErrorRounded'
import InfoRounded from '@material-ui/icons/InfoRounded'

import { Typography } from 'components/Typography'
import { SystemButton } from 'components/SystemButton'

import {
  StyledNotificationActionsWrapper,
  StyledNotificationIconWrapper,
  StyledNotificationTextWrapper,
  StyledNotificationWrapper,
} from 'components/Notification/style'

export type NotificationVariation = 'success' | 'warning' | 'error' | 'information'

export interface NotificationProps {
  description: string
  title?: string
  link?: ReactElement
  variation?: NotificationVariation
  floating?: boolean
  onClose?: MouseEventHandler<HTMLButtonElement>
}

const notificationIcon: Record<NotificationVariation, ReactElement> = {
  success: <CheckCircleRounded />,
  warning: <WarningRounded />,
  error: <ErrorRounded />,
  information: <InfoRounded />,
}

export const Notification: FC<NotificationProps> = ({
  title,
  description,
  link,
  onClose,
  variation = 'success',
  floating = false,
}) => (
  <StyledNotificationWrapper variation={variation} floating={floating} hasTitle={!!title}>
    <StyledNotificationIconWrapper>{notificationIcon[variation]}</StyledNotificationIconWrapper>

    <StyledNotificationTextWrapper>
      {title && <Typography variation="subtitleDesktopSmall">{title}</Typography>}
      <Typography variation="bodySmall">{description}</Typography>
    </StyledNotificationTextWrapper>

    <StyledNotificationActionsWrapper>
      {link}

      {onClose && (
        <SystemButton onClick={onClose}>
          <CloseRounded />
        </SystemButton>
      )}
    </StyledNotificationActionsWrapper>
  </StyledNotificationWrapper>
)
