import { FC, KeyboardEvent, MouseEventHandler, useState } from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MuiTableRow from '@material-ui/core/TableRow'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { identity, pipe } from 'ramda'
import { TableColumns } from './TableColumnCell'
import TableCell from './TableCell'
import { SystemButton } from '../SystemButton'
import { Checkbox } from 'components/Checkbox'

const trimInputIfString = (input: any) => {
  const maxStringLength = 50
  if ((typeof input === 'string' || input instanceof String) && input.length > maxStringLength) {
    return `${input.substring(0, maxStringLength)}...`
  }
  return input
}

export const StyledMuiTableRow = styled(MuiTableRow)`
  &.MuiTableRow-hover:hover {
    background-color: ${props =>
      props.theme.humanizedTokens.components.table.hover.background_color};
  }
  &&.Mui-selected {
    background-color: ${props =>
      props.theme.humanizedTokens.components.table.selected.background_color};
  }
  &.row-disabled {
    color: ${props => props.theme.humanizedTokens.components.table.disabled.color};
  }

  &:focus-visible {
    outline: 1px solid ${props => props.theme.humanizedTokens.components.table.focus.border_color};
  }
`

type TableRow<T> = T & {
  children?: Array<TableRow<T>>
  key: string
}

export type TableRowParams<T extends Record<string, any> = Record<string, any>> = {
  columns: TableColumns
  row: TableRow<T>
}

export type TableRowProps<
  T extends Record<string, any> = Record<string, any>
> = TableRowParams<T> & {
  disabled?: boolean
  isChild?: boolean
  isOpen?: boolean
  onRowClick?: (params: TableRowParams<T>, e: any) => void
  rowIndex: number
  rowsSelected?: Array<string>
  checkable?: boolean
  checkedRow?: boolean
  onCheckableClick?: MouseEventHandler<HTMLButtonElement>
}

type ExpandIconAdornmentProps = {
  isOpen?: boolean
  isVisible?: boolean
  onClick?: () => void
}

const ExpandIconAdornment: FC<ExpandIconAdornmentProps> = ({ isOpen, isVisible, onClick }) => {
  if (!isVisible) {
    return null
  }
  return (
    <Box mr={2}>
      <SystemButton aria-label="expand row" onClick={onClick}>
        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </SystemButton>
    </Box>
  )
}

function TableRow<T extends Record<string, any> = Record<string, any>>({
  isChild,
  isOpen = true,
  columns,
  onRowClick,
  row,
  rowIndex,
  rowsSelected,
  disabled,
  checkable,
  checkedRow,
  onCheckableClick,
}: TableRowProps<T>) {
  const [open, setOpen] = useState(false)
  const isSelected = ((): boolean => {
    if (!rowsSelected?.length) return false
    return rowsSelected?.filter(Boolean).includes(row?.id)
  })()
  const isBiggerScreen = useMediaQuery('(min-width:1440px)')
  const cellHeightIfOpen = isBiggerScreen ? 64 : 48
  const cellHeight = isOpen ? cellHeightIfOpen : 0

  const isRowExpandedAndHasChildren = open && row?.children

  const handleRowClick = (e: any = null) => {
    if (typeof onRowClick === 'function') {
      onRowClick({ row, columns }, e)
    }
  }

  const handleRowKeyPress = (event: KeyboardEvent<HTMLTableRowElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleRowClick()
    }
  }

  const colsWidth = columns.map(el => el.small || 'fixed')
  const countFixed = colsWidth.filter(el => el === 'fixed').length
  let fixedPercent = ((countFixed + 1) / (colsWidth.length * countFixed)) * 100
  fixedPercent = countFixed < columns.length ? fixedPercent : 100 / countFixed

  const isFocusable = Boolean(isOpen && onRowClick)

  return (
    <>
      <StyledMuiTableRow
        className={`${isChild ? 'child' : ''} ${
          isRowExpandedAndHasChildren ? 'isExpandedWithChildren' : ''
        } ${disabled ? 'row-disabled' : ''}`}
        hover
        selected={isSelected}
        onClick={handleRowClick}
        data-testid={`table-row${row?.id}`}
        tabIndex={isFocusable ? 0 : undefined}
        onKeyDown={handleRowKeyPress}
      >
        {columns.map(({ field, renderCell = identity, valueFormatter = identity }, index) => {
          const isFirstCellOfRow = index === 0
          const shouldShowExpandIcon = isFirstCellOfRow && Boolean(row?.children)
          const shouldShowCheckIcon = isFirstCellOfRow && checkable
          const formattedValue = pipe(valueFormatter, renderCell, trimInputIfString)(row?.[field])
          const colWidth = colsWidth[index] === 'fixed' ? fixedPercent.toPrecision(4) + '%' : 'auto'
          const marginLeft = isChild && isFirstCellOfRow ? 5.5 : 0

          return (
            <TableCell key={field} isOpen={isOpen} width={colWidth} height={cellHeight}>
              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Box display="flex" alignItems="center" ml={marginLeft}>
                  {shouldShowCheckIcon && (
                    <Checkbox
                      onClick={onCheckableClick}
                      checked={checkedRow || false}
                      disabled={!checkable}
                    />
                  )}
                  <ExpandIconAdornment
                    isOpen={open}
                    isVisible={shouldShowExpandIcon}
                    onClick={() => setOpen(!open)}
                  />
                  {formattedValue}
                </Box>
              </Collapse>
            </TableCell>
          )
        })}
      </StyledMuiTableRow>
      {row?.children?.map((childRow, index) => {
        return (
          <TableRow<T>
            isChild
            isOpen={open}
            key={childRow.key}
            row={childRow}
            columns={columns}
            onRowClick={onRowClick}
            rowsSelected={rowsSelected}
            rowIndex={index}
          />
        )
      })}
    </>
  )
}

export default TableRow
