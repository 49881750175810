import { brandPrimitiveTokens } from 'utils'

const { gray, green, isaacBlue, yellow, coral } = brandPrimitiveTokens.colors

const chip = {
  default: {
    backgroundColor: gray[5],
    borderColor: gray[5],
    color: gray[80],
    iconColor: gray[80],
    hover: {
      color: gray[90],
    },
  },
  focus: {
    borderColor: isaacBlue[60],
  },
  green: {
    backgroundColor: green[10],
    borderColor: green[10],
    color: green[90],
    iconColor: green[90],
    hover: {
      color: green[100],
    },
  },
  yellow: {
    backgroundColor: yellow[10],
    borderColor: yellow[10],
    color: yellow[90],
    iconColor: yellow[90],
    hover: {
      color: yellow[100],
    },
  },
  coral: {
    backgroundColor: coral[10],
    borderColor: coral[10],
    color: coral[90],
    iconColor: coral[90],
    hover: {
      color: coral[100],
    },
  },
  disabled: {
    backgroundColor: gray[10],
    borderColor: gray[10],
    color: gray[40],
    iconColor: gray[40],
  },
}

export { chip }
