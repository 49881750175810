import { brandPrimitiveTokens } from 'utils'

const {
  colors: { gray },
} = brandPrimitiveTokens

const breadcrumbs = {
  base: {
    color: gray[50],
  },
  current: {
    color: gray[80],
  },
}

export { breadcrumbs }
