import { brandPrimitiveTokens } from 'utils'

const {
  typography: { fontFamily },
} = brandPrimitiveTokens

const focus = {
  as_outline: {
    outline: '2px solid transparent',
    focus: {
      outline: `2px solid ${brandPrimitiveTokens.colors.isaacBlue[30]}`,
    },
  },
}

export { focus }
