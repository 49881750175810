import { FC, MouseEventHandler } from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded'
import TableCell from './TableCell'
import { Checkbox } from 'components/Checkbox'

type CellValue = any

export type TableColumn = {
  enableSorting?: boolean
  field: string
  headerName?: string
  isComponent?: boolean
  renderCell?: (value: CellValue) => React.ReactElement
  small?: boolean
  valueFormatter?: (value: CellValue) => CellValue
}

export type TableColumns = TableColumn[]

const StyledTableSortLabel = styled(TableSortLabel)`
  width: max-content;
  height: 48px;
`

export type CheckableState = 'checked' | 'unchecked' | 'indeterminated'

type TableColumnCellProps = {
  column: TableColumn
  direction?: 'asc' | 'desc'
  orderBy?: string
  sorted?: boolean
  sortingHandler?: (field: string) => void
  checkable?: boolean
  checkableState?: CheckableState
  selectAllHandler?: MouseEventHandler<HTMLButtonElement>
}

const handleSorting = (
  column: TableColumn,
  orderBy?: string,
  sortingHandler?: (field: string) => void
) => {
  if (column.enableSorting && sortingHandler) {
    return orderBy === column.field ? sortingHandler('') : sortingHandler(column.field)
  }
}

const TableColumnCell: FC<TableColumnCellProps> = ({
  column,
  sortingHandler,
  orderBy,
  sorted,
  direction,
  checkable = false,
  checkableState = 'unchecked',
  selectAllHandler,
}) => (
  <TableCell>
    <StyledTableSortLabel
      active={sorted && column.enableSorting}
      hideSortIcon
      direction={direction || 'asc'}
      IconComponent={column.enableSorting ? ArrowDropDownRoundedIcon : undefined}
      onClick={() => handleSorting(column, orderBy, sortingHandler)}
    >
      {checkable && (
        <Checkbox
          onClick={selectAllHandler}
          checked={checkableState === 'checked' || false}
          indeterminate={checkableState === 'indeterminated'}
        />
      )}
      <Typography variant="body2" color="textSecondary" style={{ fontWeight: 500 }}>
        {column.headerName}
      </Typography>
    </StyledTableSortLabel>
  </TableCell>
)

export default TableColumnCell
