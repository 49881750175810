import styled from 'styled-components'
import { KeyboardDatePicker as DatePicker } from '@material-ui/pickers'
import { DatePickerProps } from '.'

import { HumanizedTokens } from 'utils'

type DatePickerTokens = HumanizedTokens['components']['datePicker']

const baseElement = (dpTokens: DatePickerTokens) => `
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${dpTokens.base.hover.border_color};
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${dpTokens.base.focus.border_color};
    border-width: 2px;
  }
`

const outlinedInput = (dpTokens: DatePickerTokens) => `
  .MuiOutlinedInput{
    &-input {
      padding: 10px 14px;
      height: 1.75em;
      max-width: 100%;
    }
    &-notchedOutline {
      border-color: ${dpTokens.base.border_color};
      border-radius: 8px;
    }
    &-adornedEnd {
      padding-right: 0;
    }
  }
`

const inputLabel = (dpTokens: DatePickerTokens) => `
  .MuiInputLabel-outlined {
    transform: translate(14px, 16.5px) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
`

const typography = `
  .MuiTypography-body1 {
    font-size: 0.9rem;
  }
`

const buttonBase = (dpTokens: DatePickerTokens) => `
  .MuiButtonBase-root {
    border-radius: 4px;
    margin: 10px 12px;
    width: 28px;
    height: 28px;
    &:hover {
      background: ${dpTokens.base.hover.background_color};
      .MuiSvgIcon-root {
        fill: ${dpTokens.base.background_color};
      }
    }
  }
`

const svgIcon = `
  .MuiSvgIcon-root {
    font-size: 16px;
  }
`

export const StyledMuiDatePicker = styled(DatePicker)<DatePickerProps>`
  ${({
    theme: {
      humanizedTokens: {
        components: { datePicker },
      },
    },
  }) => `
    ${baseElement(datePicker)}
    ${outlinedInput(datePicker)}
    ${typography}
    ${svgIcon}
    ${inputLabel(datePicker)}
    ${buttonBase(datePicker)}
  `}
`
