import React from 'react'
import { ButtonProps as MuiButtonProps } from '@material-ui/core'
import classNames from 'classnames'

import { StyledMuiButton } from './Button.style'
import { CircularProgress } from 'components/CircularProgress'

type ButtonVariation = 'primary' | 'ghost'

export interface CustomProps {
  loading?: boolean
  variation?: ButtonVariation
}

export interface ButtonProps
  extends Omit<
      MuiButtonProps,
      | 'centerRipple'
      | 'color'
      | 'disableElevation'
      | 'disableFocusRipple'
      | 'disableRipple'
      | 'disableTouchRipple'
      | 'endIcon'
      | 'focusRipple'
      | 'size'
      | 'TouchRippleProps'
      | 'variant'
    >,
    CustomProps {}

const muiProps: MuiButtonProps = {
  color: 'primary',
  disableElevation: true,
}

const materialVariant = (variation: ButtonVariation) => {
  const options: Record<ButtonVariation, MuiButtonProps['variant']> = {
    primary: 'contained',
    ghost: 'text',
  }
  return options[variation]
}

const inversedLoading = (variation: ButtonVariation) => variation === 'ghost'

export const Button = ({
  variation = 'primary',
  loading = false,
  disabled = false,
  startIcon = null,
  children,
  ...props
}: ButtonProps) => {
  return (
    <StyledMuiButton
      {...muiProps}
      {...props}
      disabled={disabled || loading}
      startIcon={startIcon}
      variant={materialVariant(variation)}
      variation={variation}
      className={classNames(`--${variation}`, {
        '--is-loading': loading,
        '--with-icon': !!startIcon,
      })}
    >
      {children}
      {loading && <CircularProgress inverse={inversedLoading(variation)} />}
    </StyledMuiButton>
  )
}
