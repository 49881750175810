import styled from 'styled-components'

import { IconButton } from 'components/IconButton'
import CancelRounded from '@material-ui/icons/CancelRounded'
import { Typography } from 'components/Typography'
import { FC, MouseEventHandler } from 'react'

export interface StyledTableActionBarWrapperProps {
  isOpen: boolean
}

const StyledTableActionBarWrapper = styled.div<StyledTableActionBarWrapperProps>(
  ({
    theme: {
      primitiveTokens: {
        colors: { isaacBlue },
      },
    },
    isOpen,
  }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 0,
    padding: '0 12px',
    backgroundColor: isaacBlue[60],
    overflow: 'hidden',
    transition:
      'height 0.1s cubic-bezier(.17,.67,.72,.66), padding 0.1s cubic-bezier(.17,.67,.72,.66)',
    [Typography]: {
      margin: 0,
      textTransform: 'none',
    },
    ...(isOpen
      ? {
          height: 48,
          padding: '8px 12px',
        }
      : {}),
  })
)

export interface TableActionBarProps {
  counter: number
  onClear: MouseEventHandler<HTMLButtonElement>
}

export const TableActionBar: FC<TableActionBarProps> = ({ counter, onClear }) => (
  <StyledTableActionBarWrapper isOpen={counter > 0}>
    <Typography variation="buttonLarge" color="accentContrast">
      {counter} {counter === 1 ? 'Item' : 'Itens'} selecionado{counter === 1 ? '' : 's'}
    </Typography>

    <IconButton size="small" onClick={onClear}>
      <CancelRounded />
    </IconButton>
  </StyledTableActionBarWrapper>
)
