import { brandPrimitiveTokens } from 'utils'

const {
  colors: { isaacBlue, gray },
  spacing,
} = brandPrimitiveTokens

const radio = {
  base: {
    margin_right: spacing[2],
    before: {
      border_radius: '2px',
      transition: 'box-shadow 0.3s cubic-bezier(.17,.67,.72,.66)',
    },
  },
  checked: {
    color: isaacBlue[60],
    hover: {
      color: isaacBlue[80],
    },
    press: {
      color: isaacBlue[80],
    },
    disable: {
      color: isaacBlue[20],
    },
  },
  disable: {
    color: gray[40],
    label: {
      color: gray[40],
    },
  },
  focus: {
    box_shadow: `0 0 0 2px ${isaacBlue[30]}`,
  },
  hover: {
    color: gray[70],
  },
  press: {
    color: gray[90],
  },
}

export { radio }
