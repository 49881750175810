import { HTMLAttributes } from 'react'
import {
  SideMenuIcon,
  SideMenuTextIcon,
  SideMenuLink as StyledSideMenuLink,
  SideMenuLabel,
} from './styles'

export interface SideMenuLinkProps extends HTMLAttributes<HTMLAnchorElement> {
  icon: React.SVGProps<SVGSVGElement> | string
  label: string
  disabled?: boolean
  active?: boolean
  wrapperAs?: string | React.ComponentType<any> | undefined
  href?: string
}

const defaultSideMenuLinkProps: SideMenuLinkProps = {
  icon: '',
  label: 'texto',
  wrapperAs: 'a',
  disabled: false,
  active: false,
  href: undefined,
}

export const SideMenuLink = ({
  icon,
  label,
  wrapperAs,
  ...props
}: SideMenuLinkProps = defaultSideMenuLinkProps) => (
  <StyledSideMenuLink as={wrapperAs} {...props}>
    {typeof icon === 'string' ? (
      <SideMenuTextIcon>{icon}</SideMenuTextIcon>
    ) : (
      <SideMenuIcon>{icon}</SideMenuIcon>
    )}
    <SideMenuLabel>{label}</SideMenuLabel>
  </StyledSideMenuLink>
)
