import { brandPrimitiveTokens } from 'utils'

const {
  colors: { gray },
  spacing,
} = brandPrimitiveTokens

const tooltipButton = {
  base: {
    border_radius: '8px',
    padding: spacing[0],
    box_shadow: `0 0 0 2px transparent`,
    transitions: {
      base: 'all 0.2s cubic-bezier(.17,.67,.72,.66)',
      focus: 'box-shadow 0.3s cubic-bezier(.17,.67,.72,.66)',
    },
  },
  enable: {
    text_color: gray[50],
  },
  hover: {
    background_color: 'transparent',
    text_color: gray[70],
  },
}

export { tooltipButton }
