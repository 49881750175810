import { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox'
import { StyledCheckbox } from './style'

export interface CheckboxProps
  extends Omit<
    MuiCheckboxProps,
    | 'centerRipple'
    | 'color'
    | 'checkedIcon'
    | 'disableFocusRipple'
    | 'disableRipple'
    | 'disableTouchRipple'
    | 'edge'
    | 'focusRipple'
    | 'icon'
    | 'indeterminateIcon'
    | 'size'
    | 'TouchRippleProps'
  > {}

export const Checkbox = (props: CheckboxProps) => {
  return <StyledCheckbox color="primary" {...props} disableRipple />
}
