import { brandPrimitiveTokens } from 'utils'

const {
  colors: { isaacBlue, gray },
  spacing,
} = brandPrimitiveTokens

const button = {
  base: {
    padding: spacing[3],
    box_shadow: `0 0 0 2px transparent`,
    transitions: {
      base: 'all 0.2s cubic-bezier(.17,.67,.72,.66)',
      focus: 'box-shadow 0.3s cubic-bezier(.17,.67,.72,.66)',
    },
  },
  primary: {
    press: {
      background_color: isaacBlue[80],
    },
    disable: {
      background_color: gray[10],
      text_color: gray[40],
    },
    load: {
      background_color: isaacBlue[60],
    },
    focus: {
      box_shadow: `0 0 0 2px ${isaacBlue[30]}`,
    },
  },
  ghost: {
    hover: {
      background_color: `${isaacBlue[60]}14`,
    },
    press: {
      background_color: `${isaacBlue[60]}1F`,
    },
    disable: {
      background_color: 'transparent',
      text_color: gray[40],
    },
    focus: {
      box_shadow: `0 0 0 2px ${isaacBlue[30]}`,
    },
  },
  withIcon: {
    padding: `${spacing[3]} ${spacing[4]} ${spacing[3]} ${spacing[3]}`,
  },
}

export { button }
